import Axios from 'axios';

export default class CartApi {
  constructor() {
    this.axios = Axios.create({
      baseURL: `${process.env.CHECKOUT_ENDPOINT}/wp-json/cocart/`,
      timeout: 20000,
      headers: { 'Content-Type': `application/json` }
    });
  }

  async AddProductOnCart(cartId, product) {
    let response;
    try {
      response = await this.axios.post(`v2/cart/add-item?cart_key=${cartId}`, {
        id: product.id,
        quantity: product.qtd.toString()
      });

      return { productId: product.id, data: response.data };
    } catch (error) {
      const qtdRemaining = CartApi.getQtdRemaining(error.response.data.message);
      return {
        productId: product.id,
        error: error.response.data,
        qtdRemaining
      };
    }
  }

  async clearCart(cartId) {
    try {
      const response = await this.axios.post(
        `v2/cart/clear?cart_key=${cartId}`
      );
      return { data: response.data };
    } catch (error) {
      return { error: error.response.data };
    }
  }

  static getQtdRemaining(stringResponse) {
    const regexQtd1 = /^.*out of stock.*$/;
    const regexQtd2 = /have (\d{1,}) in stock and/;
    const regexQtd3 = /only (\d{1,}) remaining/;
    const regexQtd4 = /Quantity must be (\d{1,}) or lower/; //new message from cocart v2 api
    try {
      if (stringResponse.match(regexQtd1)) {
        return stringResponse.match(0);
      }
      if (stringResponse.match(regexQtd2)) {
        return stringResponse.match(regexQtd2)[1];
      }
      if (stringResponse.match(regexQtd3)) {
        return stringResponse.match(regexQtd3)[1];
      }
      if (stringResponse.match(regexQtd4)) {
        return stringResponse.match(regexQtd4)[1];
      }
    } catch (error) {
      return null;
    }
  }

  static async sleep(msParam) {
    let ms = msParam;
    const randomInt = (min, max) => {
      return min + Math.floor((max - min) * Math.random());
    };
    if (!ms) {
      ms = randomInt(800, 2000);
    }
    return new Promise(resolve => {
      setTimeout(() => {
        resolve();
      }, ms);
    });
  }
}
