import React from 'react';

import InputNumber from './InputNumber';
import { FaTrashAlt } from 'react-icons/fa';

const CartItem = props => {
  return (
    <div className="w-full md:p-1">
      {props.warning && props.warning.message && (
        <div className="items-center bg-red-600 text-white text-sm font-bold rounded py-1 px-4 mt-1">
          {props.warning.message}
        </div>
      )}
      <div className="flex border-b py-2">
        <div className="mx-2 pt-3">
          <button
            className="w-full md:w-auto my-2 mx-0 md:m-2 p-0 rounded shadow text-white font-bold bg-black hover:bg-amarelo hover:text-black"
            onClick={() => props.deleteProductOnCart(props.id)}
          >
            <FaTrashAlt className="h-3 w-3 m-2" />
          </button>
        </div>

        <div className="flex w-1/6">
          <div className="pr-2 lg:pr-4 overflow-hidden">
            <img
              alt={props.name}
              async="on"
              className="object-cover h-16 w-16"
              src={props.img}
            />
          </div>
        </div>
        <div className="flex w-4/6">
          <div>
            <div className="text-sm lg:text-lg font-bold text-gray-700">
              {props.name}
            </div>
            <div className="text-sm lg:text-lg">
              {(props.price * 1).toLocaleString(`pt-BR`, {
                style: `currency`,
                currency: `BRL`
              })}
            </div>
          </div>
        </div>
        <div className="mx-2 right-0">
          <InputNumber
            className="w-20"
            defaultValue={props.qtd}
            mobile={false}
            onChange={value => props.changeQtdCartProduct(props.id, value)}
          />
        </div>
      </div>

      <hr />
    </div>
  );
};

export default CartItem;
