import React from 'react';
import { NotificationManager } from 'react-notifications';
import { v4 as uuidv4 } from 'uuid';
import SEO from '../components/Seo';
import StoreContext from '../context/StoreContext';
import CartList from '../components/CartList';
import { Circle } from 'rc-progress';
import CartApi from '../config/CartApi';
import { navigate } from '@reach/router';
import { AiFillDollarCircle } from 'react-icons/ai';

class CartPage extends React.Component {
  constructor() {
    super();
    this.cartApi = new CartApi();
    this.state = {
      totalRequests: 0,
      finalizedRequests: 0,
      showProgress: false,
      warningsCarts: {}
    };
    this.cartValidate = 14400000; // 4 hours in milliseconds
  }

  calcTotalPrice = products => {
    let added = 0;
    Object.keys(products).forEach(id => {
      added += products[id].price * products[id].qtd;
    });
    return added;
  };

  makeCheckout = async (products, cartInfo, fnSaveCartInfo) => {
    let idCheckout;
    const responses = [];
    this.setState({
      showProgress: true,
      totalRequests: Object.entries(products).length
    });
    if (
      !cartInfo ||
      !cartInfo.uuid ||
      new Date() - cartInfo.dateCreated <= this.cartValidate
    ) {
      idCheckout = uuidv4();
      fnSaveCartInfo({
        dateCreated: new Date(),
        uuid: idCheckout
      });
    } else {
      idCheckout = cartInfo.uuid;
    }

    await this.cartApi.clearCart(idCheckout);

    for await (const [keyId, value] of Object.entries(products)) {
      const response = await this.cartApi.AddProductOnCart(idCheckout, {
        id: keyId,
        qtd: value.qtd
      });
      responses.push(response);
      this.setState(prevState => ({
        finalizedRequests: prevState.finalizedRequests + 1
      }));
    }

    this.verifyStatusCart(responses, idCheckout);
  };

  verifyStatusCart = (responsesApi, idCart) => {
    const newWarningsCarts = {};
    responsesApi.forEach(response => {
      if (response.error) {
        newWarningsCarts[response.productId] = {
          type: `INSUFFICIENT_STOCK`,
          message: `Ops! Só temos ${response.qtdRemaining} destas!`
        };
        // notification for out of stock
        if (response.qtdRemaining < 1) {
          newWarningsCarts[response.productId] = {
            type: `OUT_OF_STOCK`,
            message: `Este produto acabou! :(`
          };
        }

        NotificationManager.warning(`Verifique as quantidades de seu pedido!`);
      }
    });
    this.setState(
      {
        showProgress: false,
        warningsCarts: newWarningsCarts,
        totalRequests: 0,
        finalizedRequests: 0
      },
      () => {
        if (Object.keys(this.state.warningsCarts).length === 0) {
          navigate(
            `${process.env.CHECKOUT_ENDPOINT}/?cocart-load-cart=${idCart}`
          );
        }
      }
    );
  };

  getLinkCheckout = products => {
    let link = `${process.env.CHECKOUT_ENDPOINT}/?add-more-to-cart=`;
    Object.keys(products).map(async (productId, index) => {
      const qtd = products[productId].qtd;
      for (let cont = 0; cont < qtd; cont++) {
        link += `${productId},`;
      }
    });
    return link.slice(0, -1); // slice string to remove last comma
  };

  render() {
    const percent =
      (this.state.finalizedRequests / this.state.totalRequests) * 100 || 0;
    return (
      <StoreContext.Consumer>
        {context => {
          if (!context.state) {
            return <></>;
          }
          return (
            <>
              <SEO title="Cart Page" />
              {this.state.showProgress && (
                <div className="fixed w-full h-full bg-white bg-opacity-75 p-20 z-50">
                  <div className="text-center text-primary py-10 text-xl font-bold bg-white">
                    Estamos fechando seu pedido!
                  </div>
                  <div className="flex justify-center">
                    <Circle
                      className="w-64"
                      percent={percent}
                      strokeColor="#570E79"
                      strokeWidth="5"
                    />
                  </div>
                </div>
              )}
              <div className="w-full mx-2 xl:mx-56">
                <div className="py-4 font-bold text-gray-700">
                  Carrinho de compras
                </div>

                <CartList
                  changeQtdCartProduct={context.changeQtdCartProduct}
                  deleteProductOnCart={context.deleteProductOnCart}
                  products={context.state.productsOnCart}
                  warningsCarts={this.state.warningsCarts}
                />

                <div className="text-right mx-4 my-2 font-bold">
                  {Object.keys(context.state.productsOnCart).length > 0 && (
                    <>
                      <div className="lg:flex lg:flex-row-reverse lg:justify-around">
                        <div className="p-2">
                          <div className="lg:text-2xl text-primary">
                            Total à prazo:{` `}
                            {this.calcTotalPrice(
                              context.state.productsOnCart
                            ).toLocaleString(`pt-BR`, {
                              style: `currency`,
                              currency: `BRL`
                            })}
                          </div>
                          <div className="lg:text-2xl text-primary">
                            Pagamento via Pix:{` `}
                            {(
                              this.calcTotalPrice(
                                context.state.productsOnCart
                              ) * 0.9
                            ).toLocaleString(`pt-BR`, {
                              style: `currency`,
                              currency: `BRL`
                            })}
                          </div>
                          {/* <a
                            href={link}
                            onClick={context.clearCartProducts}
                          > */}
                          <button
                            className="w-full lg:w-auto my-2 mx-0 lg:m-2 p-3 rounded shadow text-white font-bold bg-black hover:bg-amarelo hover:text-black"
                            onClick={() =>
                              this.makeCheckout(
                                context.state.productsOnCart,
                                context.state.cartInfo,
                                context.saveCartInfo
                              )
                            }
                          >
                            Pagar Agora
                            <AiFillDollarCircle className="w-8 h-8 inline align-middle ml-2" />
                          </button>
                          {/* </a> */}
                        </div>
                        <div className="p-2"></div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </>
          );
        }}
      </StoreContext.Consumer>
    );
  }
}

export default CartPage;
