import React from 'react';
import CartItem from './CartItem';

const CartList = props => {
  const { products, warningsCarts } = props;
  return (
    <>
      {Object.keys(products).map(prd => (
        <CartItem
          changeQtdCartProduct={props.changeQtdCartProduct}
          deleteProductOnCart={props.deleteProductOnCart}
          id={prd}
          img={products[prd].img}
          key={prd}
          name={products[prd].name}
          price={products[prd].price}
          qtd={products[prd].qtd}
          warning={warningsCarts[prd]}
        />
      ))}
    </>
  );
};

export default CartList;
